/* eslint-disable react/prop-types */
import React from 'react';
import arrow from '../../img/resources_arrow.svg'

const LinkPanelArrow = (props) => {
    return (
    <>
        <div onClick={() => {
                window.location.href = props.link;
            }}
        
            className="hover cursor-pointer mx-6 my-6 rounded-md bg-gradient-to-br from-fvyellow via-fvorange to-fvpurple p-[2px]">
            <div className="py-8 h-full w-full rounded-md sm:items-center sm:justify-center 
                            bg-gradient-to-r from-fvdarkpurple to-fvpurple">
                <div className="flex flex-row items-center justify-center">
                    <div className="font-bold text-xl w-3/4">
                        {props.text}
                    </div>
                    <div className="">
                        <img src={arrow} alt="arrow" />
                    </div> 
                </div>
            </div>
        </div>
    </>
    );

}

export default LinkPanelArrow;