/* eslint-disable react/prop-types */ 
import React from 'react';



const Grants = () => {

    return (
    <>
    <section className="lg:mt-[-128px] lg:pt-[128px] bg-cover bg-center bg-[url('./img/HERO_GRANT.jpg')]">
        <div className="container mx-auto px-10 pb-10">
            <div className="sm:w-1/2 grid grid-cols-1 place-items-center">
                <div className="col-span-2 pt-10 text-left">
                    <h1 className="pb-10 header-title text-3xl md:text-5xl leading-none lg:leading-tight">LAOS Network Grants Programe</h1>
                    <div className='text-xl xl:text-2xl w-3/4 sm:w-full'>
                    The LAOS Foundation Grants Program is designed to empower a broad spectrum of blockchain initiatives, focusing on both technological innovation and vibrant community involvement.
                    </div>
                </div>
            </div>
        </div>
    </section >
    <section className="bg-[#F7F7F7]">
        <div className="text-[#707070] text-justify text-lg md:text-xl container mx-auto px-10 md:w-3/4 py-10">
        We are committed to supporting projects that harness the distinctive capabilities of the LAOS platform, as well as initiatives that contribute to the growth and enrichment of our community.
        </div>
    </section>
    <section className="bg-white py-10 md:py-20">
        <div className="container mx-auto px-10">
            <h2 className="text-fvpurple text-center font-bold text-3xl md:text-5xl pb-4 md:pb-10">Who can apply?</h2>
            <h3 className="text-fvpurple text-center font-bold text-lg sm:text-xl md:text-2xl pb-2">We welcome applications from a diverse range of participants, including but not limited to:</h3>
            <ul className='mx-auto sm:px-20 list-disc list-inside text-[#707070] text-lg md:text-xl md:py-10 space-y-2'>
                <li>Individuals and Independent Innovators</li>
                <li>Teams and Collaborative Groups</li>
                <li>Government Entities and Public Sector Organizations</li>
                <li>Non-Profit Organizations</li>
                <li>Private Companies and Startups</li>
                <li>Academic and Research Institutions</li>
            </ul>
            <div className='mx-auto sm:px-20 text-[#707070] text-lg md:text-xl'>
                <span className="font-bold text-fvpurple">Note: </span> 
                At the current stage, we are unable to accept anonymous projects.
            </div>
            <h2 className="text-fvpurple text-center font-bold text-3xl md:text-5xl py-4 md:py-10">Funding Areas</h2>
            <h3 className="text-fvpurple text-center font-bold text-lg sm:text-xl md:text-2xl">We are primarily interested in funding projects in the following areas:</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 space-y-2 lg:space-x-10 lg:space-y-0 mt-10">
                <div className="w-full rounded-lg bg-gradient-to-r from-fvgrad1 via-fvgrad2 to-fvgrad3 p-[2px]">
                    <div className="w-full h-full rounded-lg bg-white p-10">
                        <div className="font-bold text-fvpurple text-xl">Building on LAOS</div>
                        <ul className='mx-auto list-disc list-inside text-[#707070] text-lg md:text-xl md:py-4'>
                            <li>Metadata Extension Apps and Integrations</li>
                            <li>Evolution of Existing NFT Collections</li>
                            <li>New Game Development and Existing Game Integrations</li>
                            <li>SDK Development</li>
                            <li>Marketplace Integrations</li>
                            <li>Real-World Asset Applications</li>
                            <li>Wallet integration</li>
                            <li>DEX Pools Incentives</li>
                            <li>CEX Integrations</li>
                            <li>On-Ramp Integrations</li>
                            <li>UGC Integrations</li>
                            <li>Node Provider</li>
                        </ul>
                    </div>
                </div>
                <div className="w-full rounded-lg bg-gradient-to-r from-fvgrad1 via-fvgrad2 to-fvgrad3 p-[2px]">
                    <div className="w-full h-full rounded-lg bg-white p-10">
                        <div className="font-bold text-fvpurple text-xl">Community Engagement</div>
                        <ul className='mx-auto list-disc list-inside text-[#707070] text-lg md:text-xl md:py-4'>
                            <li>Educational Tutorials and Resources</li>
                            <li>Promotional Videos and Art</li>
                            <li>Hosting or Producing Podcasts</li>
                            <li>Organizing and Supporting Events, Meetups & Hackathons</li>
                        </ul>
                    </div>
                </div>
            </div>
            <h3 className="font-bold mt-10 text-fvpurple text-center text-xl md:text-2xl pb-1">Process Details</h3>
            <ul className='mx-auto sm:px-20 list-inside text-[#707070] text-md md:text-lg md:py-4 space-y-6'>
                <li><span className="font-bold text-fvpurple">Delivery:</span> LAOS Foundation grants are usually take the form of tokens, where the USD equivalent value is calculated at the time of delivery. Grant delivery may be associated with Milestones, and tokens may have a vesting period. 
                In very special cases, certain projects may be qualify for a portion of the grant to be paid in FIAT currency. <span className="font-bold text-fvpurple">Grant amounts approved before the official LAOS token launch will be delivered post-launch.</span></li> 
                <li><span className="font-bold text-fvpurple">Application:</span> Applicants are required to submit a proposal including the project description, objectives, timeline, budget, and expected impact. Applications that do not address these points are unlikely to qualify for a grant.</li>
                <li><span className="font-bold text-fvpurple">Selection Criteria: </span> 
                Proposals will be evaluated by the LAOS Foundation&apos;s Grant Committee based on their innovation, potential impact, feasibility, and alignment with our program&apos;s goals, 
                ensuring each submission undergoes a detailed and fair assessment. We may reach out for more details or propose a meeting to better understand your project&apos;s scope and objectives. </li>
                <li><span className="font-bold text-fvpurple">Final Decision:</span>  Notification of our decision will be sent to applicants via email. Whether approved or not, all applicants will receive prompt communication regarding the status of their proposal. Each decision is final, though rejected applicants may reapply with a modified proposal.</li>
                <li><span className="font-bold text-fvpurple">Amount:</span> Our grants vary depending on project scope and requirements. During your application, you should provide full justification for the amount you are applying for, and indicate any other funding sources. A project may be awarded a partial amount of that requested.</li>
            </ul>
            <h3 className="font-bold mt-10 text-fvpurple text-center text-xl md:text-2xl pb-1">Support and Resources</h3>

            <ul className='mx-auto sm:px-20 list-inside text-[#707070] text-md md:text-lg md:py-4 space-y-6'>
                <li>Successful applicants will receive not only financial support but also:</li>
                <li><span className="font-bold text-fvpurple">Technical Support:</span> Access to our network of experts and mentors.</li> 
                <li><span className="font-bold text-fvpurple">Marketing Support:</span> Promotional support to amplify project visibility.</li>
                <li><span className="font-bold text-fvpurple">Collaboration Opportunities: </span> Opportunities for collaboration and partnership within our ecosystem. </li>
            </ul>

            <div onClick={() => window.location = "https://form.typeform.com/to/A6WvByOu"} className="text-center font-bold w-40 my-10 mx-auto text-fvpurple bg-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvpurple hover:text-fvyellow hover:cursor-pointer">Apply Now</div>
        </div>
    </section>
    </>
    );
};

export default Grants;